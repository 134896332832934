/** @jsx jsx */

import { useEffect } from 'react';
import { Container, Heading, jsx, Box } from 'theme-ui';
import { useAuth, useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { navigate } from 'gatsby';
import LoginForm from '~/components/Generic/Form/Login';
import AugustA from '~/assets/images/A-logo.svg';
import WelcomeCircle from '~/assets/images/welcome-circle.svg';

const SigninPage = () => {
  const { isLoggedIn, getToken } = useAuth();
  const { loadUser } = useUser();

  const checkStatus = async () => {
    try {
      await getToken();
      loadUser();
      // setIsLoggedIn(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/account');
    } else {
      checkStatus();
    }
  }, [isLoggedIn]);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        pb: ['6rem', '8rem'],
      }}
    >
      <Box
        sx={{
          display: ['none', 'block'],
          my: ['2rem', null, '4.5rem'],
          mb: ['4.5rem', null, '6.75rem'],
          '& > svg': {
            width: ['40px', null, '80px'],
            height: ['36px', null, '72px'],
          },
        }}
      >
        <AugustA />
      </Box>
      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          '& > svg': {
            width: ['77px', null, '167px'],
            height: ['77px', null, '167px'],
          },
        }}
      >
        <WelcomeCircle />
      </Box>
      {/* This is a hidden element for EO's Missing H1 audit report seeing as there's no descriptive header on this page  */}
      <Heading as="h1" sx={{ height: '0px', opacity: '0' }}>
        Signin
      </Heading>

      <LoginForm />
    </Container>
  );
};

export default SigninPage;
