/** @jsx jsx */
import { Input, Text, jsx, useThemeUI, Button, Flex, Heading, Box, Link } from 'theme-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';
import AppleIcon from '../../../assets/images/icons/logo-apple.svg';
import GoogleIcon from '../../../assets/images/icons/logo-google.svg';

const LoginForm = () => {
  const { register, handleSubmit } = useForm();
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const [error, setError] = useState('');
  const { theme } = useThemeUI();

  const { serviceClient } = useAuth();

  const onSubmit = handleSubmit(async (fields) => {
    setIsLoading(true);
    setEmail(fields.email);
    const response = await serviceClient.magicLinks.email.loginOrCreate(fields.email, {
      login_magic_link_url: `${window.location.origin}/account/authenticate`,
      signup_magic_link_url: `${window.location.origin}/account/authenticate`,
    });

    if (!response.status_code || response.status_code !== 200) {
      setError('Something went wrong. Please try again later');
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setDidSubmit(true);
  });

  const signInWithApple = async () => {
    const response = await serviceClient.oauth.apple.start({
      login_redirect_url: `${window.location.origin}/account/authenticate`,
      signup_redirect_url: `${window.location.origin}/account/authenticate`,
    });
  };

  const signInWithGoogle = async () => {
    const response = await serviceClient.oauth.google.start({
      login_redirect_url: `${window.location.origin}/account/authenticate`,
      signup_redirect_url: `${window.location.origin}/account/authenticate`,
    });
  };

  if (didSubmit)
    return (
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Heading as="h3" variant="h3" sx={{ my: '1.6rem', textAlign: 'center' }}>
          CHECK YOUR EMAIL
        </Heading>

        <Text
          sx={{
            textAlign: 'center',
            fontFamily: 'merriweather',
            width: ['70%'],
            margin: '4rem auto',
            textTransform: 'none',
            fontSize: [11, 12, 13],
          }}
        >
          We sent an email to {email}. Please click the link to log in or sign up.
        </Text>

        <Text sx={{ mb: '8px' }}>Didn't get an email?</Text>

        <Button onClick={() => setDidSubmit(false)} variant="tertiary">
          Try again
        </Button>
      </Flex>
    );

  return (
    <Flex
      as="form"
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: ['42rem', '72rem'],
        mb: '2rem',
        fieldset: {
          flexDirection: 'column',
          justifyContent: [null, 'center'],
          width: '100%',
          border: 0,
          py: ['1.6rem', 0],
          px: 0,
          mb: ['1.4rem', '2.4rem'],
        },
      }}
      onSubmit={onSubmit}
    >
      <Heading
        as="h3"
        variant="h3"
        sx={{ fontSize: [11], my: '1.6rem', textAlign: 'center', mb: ['1.6rem', '6rem'] }}
      >
        Sign up or log in
      </Heading>

      <Flex sx={{ flexDirection: ['column', 'row'], mb: [null, '4rem'] }}>
        <Flex
          as="fieldset"
          sx={{
            flexDirection: 'column',
            button: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              py: '1.2rem !important',
            },
            svg: {
              path: { fill: 'alizarinCrimson' },
              width: '24px',
              mr: '1.6rem',
              verticalAlign: 'middle',
            },
          }}
        >
          <Button
            type="button"
            onClick={signInWithApple}
            sx={{
              fontSize: [5, 7],
              mb: ['1.4rem', '2.4rem'],
              py: ['1rem !important', '1.2rem !important'],
            }}
          >
            <AppleIcon sx={{}} />
            Continue with Apple
          </Button>

          <Button
            type="button"
            onClick={signInWithGoogle}
            sx={{
              fontSize: [5, 7],
              mb: ['1.4rem', '2.4rem'],
              py: ['1rem !important', '1.2rem !important'],
            }}
          >
            <GoogleIcon />
            Continue with Google
          </Button>

          <Text sx={{ textAlign: 'center', fontSize: [3, 4] }}>
            If you have purchased with us before, please use the same email address to see all past
            orders and/or subscriptions. If a different email is used, a new account will be
            created.
          </Text>
        </Flex>

        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: ['100%', '5rem'],
            position: 'relative',
            textAlign: 'center',
            mb: '1.6rem',
            mx: [null, '4rem'],
            '::before': {
              content: '""',
              position: 'absolute',
              top: ['50%', 0],
              left: [0, '50%'],
              width: ['calc(50% - 2.4rem)', '1px'],
              height: ['1px', 'calc(50% - 2.4rem)'],
              backgroundColor: 'alizarinCrimson',
            },
            '::after': {
              content: '""',
              position: 'absolute',
              top: ['50%', 'initial'],
              bottom: [null, 0],
              right: [0, '50%'],
              width: ['calc(50% - 2.4rem)', '1px'],
              height: ['1px', 'calc(50% - 2.4rem)'],
              backgroundColor: 'alizarinCrimson',
            },
          }}
        >
          <Text as="span">Or</Text>
        </Flex>

        <Flex as="fieldset" sx={{ alignItems: 'center' }}>
          <Input
            ref={register()}
            name="email"
            type="email"
            placeholder="EMAIL"
            sx={{
              width: '100%',
              height: ['50px'],
              color: 'alizarinCrimson',
              fontWeight: 300,
              fontSize: ['1.4rem !important', '1.6rem !important'],
              lineHeight: ['18.75px', null, null, '39.64px'],
              letterSpacing: ['-5%'],
              border: `2px solid ${theme.colors.chatelle}`,
              borderRadius: '18px',
              backgroundColor: 'canary',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              '&:focus': {
                outline: 'none',
              },
              '&::-webkit-input-placeholder': {
                fontWeight: 300,
                color: 'chatelle',
                fontSize: ['17px', null, null, '24px'],
              },
              '&::-ms-input-placeholder': {
                fontWeight: 300,
                color: 'chatelle',
                fontSize: ['17px', null, null, '24px'],
              },
              '&::-moz-input-placeholder': {
                fontWeight: 300,
                color: 'chatelle',
                fontSize: ['17px', null, null, '24px'],
              },
              '&::-placeholder': {
                fontWeight: 300,
                color: 'chatelle',
                fontSize: ['17px', null, null, '24px'],
              },
            }}
          />

          {error && <Text sx={{ my: '0.8rem' }}>{error}</Text>}

          <Button
            type="submit"
            variant="tertiary"
            sx={{
              width: '100%',
              mt: ['1.4rem', '2.4rem'],
              textTransform: 'uppercase',
              fontWeight: 'bold',
              py: '1.2rem !important',
              mb: ['1.4rem', '2.4rem'],
              ':disabled': { opacity: 0.5, cursor: 'not-allowed' },
            }}
            disabled={isLoading}
          >
            Continue with email
          </Button>

          <Text sx={{ textAlign: 'center', fontSize: [3, 4] }}>
            Just enter the email you used with your last order and an email will be sent with a log
            in confirmation link.
          </Text>
        </Flex>
      </Flex>

      <Text sx={{ textAlign: 'center' }}>
        If you have any issues logging in, please reach out to{' '}
        <Link to="mailto:care@itsaugust.co" target="_blank" sx={{ textDecoration: 'underline' }}>
          care@itsaugust.co
        </Link>{' '}
        before your subscription renews.
      </Text>
    </Flex>
  );
};

export default LoginForm;
