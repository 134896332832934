/** @jsx jsx */
import { jsx } from 'theme-ui';
// import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import SigninPage from '~/components/Pages/Account/Signin';
import Metadata from '~/components/Metadata';

const Signin = () => (
  <Layout hideWidget>
    <Metadata title="Signin" description="Signin to your August account" />
    <SigninPage />
  </Layout>
);

export default Signin;
